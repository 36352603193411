////////////////////////////////
//Alerts//
////////////////////////////////
// bootstrap alert CSS, translated to the django-standard levels of
// debug, info, success, warning, error
.alert-debug {
    background-color: $white;
    border-color: $mint-green;
    color: $black;
}

.alert-error {
    background-color: $pink;
    border-color: $dark-pink;
    color: $red;
}
