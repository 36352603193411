// Default variable overrides
@import "variables";
//
// Vendors
//
@import "../../node_modules/bootstrap/scss/bootstrap.scss";
@import "../../node_modules/tabulator-tables/dist/css/tabulator_bootstrap5.css";
//
// Fontawesome
// https://fontawesome.com/docs/web/use-with/scss#adding-font-awesome-to-your-compile
//
$fa-font-path: "../../node_modules/@fortawesome/fontawesome-free/webfonts";
@import "../../node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "../../node_modules/@fortawesome/fontawesome-free/scss/solid.scss";
@import "../../node_modules/@fortawesome/fontawesome-free/scss/regular.scss";
//@import "../../node_modules/@fortawesome/fontawesome-free/scss/brands.scss";
//
// Project specific
//
@import "./includes/base";
@import "./includes/brand";
@import "./includes/cookie-notes";
@import "./includes/tabulator";
@import "./includes/alert";
// Page specific
@import "./pages/dashboard";
@import "./pages/reservation-detail";
