html,
body {
    height: 100vh;
}

.page-wrapper,
.container {
    height: 90%;
}

a {
    text-decoration: none;
     :hover {
        text-decoration: underline;
    }
}

.header {
    position: sticky;
    background-color: #ffffff;
    top: 0;
}

#map {
    height: 400px;
}

.big-checkbox {
    width: 2em;
    height: 2em;
}

.borderless {
    border: none;
}

.button-offcanvas {
    position: relative;
    top: 4px;
    left: 0px;
    width: 16px;
    height: 16px;
    border: none;
}

.button-line-offcanvas {
    position: fixed;
    top: 0;
    left: 0;
    width: 16px;
    height: 100vh;
    padding: 0;
    border-width: 1px;
    border-style: solid;
    border-color: $gray-600;
    color: $gray-600;
}

// fix bug in django-autocompleate-light with field location design
.select2-container--default .select2-selection--single .select2-selection__arrow {
    top: 8px!important;
    right: 7px!important;
}

.select2-container--default .select2-selection--single .select2-selection__clear {
    left: 0px!important;
    top: 0px!important;
}

.select2-container .select2-selection--single {
    height: inherit!important;
}
