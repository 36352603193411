////////////////////////////////
//Variables//
////////////////////////////////
$white: #fff;
$mint-green: #d6e9c6;
$black: #000;
$pink: #f2dede;
$dark-pink: #eed3d7;
$red: #b94a48;
// Выделение прямоугольной области цветом фона
$box-highlight: #dee2e6;
// Navibar
$navbar-dark-brand-color: Yellow;
$navbar-dark-brand-effect-color: red;
// Breadscrum
$breadcrumb-margin-bottom: .25rem;
$breadcrumb-font-size: .75rem;
.breadcrumb a {
    color: var(--bs-breadcrumb-item-active-color);
}
