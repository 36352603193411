/* Hide all icons by default */

.tabulator-col .tabulator-col-sorter i {
    display: none;
}


/* Display the fa-sort icon when the column is not sorted */

.tabulator-col[aria-sort="none"] .tabulator-col-sorter i.fa-sort {
    display: inline-block;
    color: #999;
}


/* Display the fa-sort-up icon when the column is sorted in ascending order */

.tabulator-col[aria-sort="ascending"] .tabulator-col-sorter i.fa-sort-up {
    display: inline-block;
    color: #f00;
}


/* Display the fa-sort-down icon when the column is sorted in descending order */

.tabulator-col[aria-sort="descending"] .tabulator-col-sorter i.fa-sort-down {
    display: inline-block;
    color: #f00;
}
