#cookie_note {
    display: none;
    position: fixed;
    bottom: 15px;
    left: 50%;
    max-width: 90%;
    transform: translateX(-50%);
    padding: 20px;
    background-color: white;
    border-radius: 4px;
    box-shadow: 2px 3px 10px rgba(0, 0, 0, 0.4);
    p {
        margin: 0;
        font-size: 0.9rem;
        text-align: left;
        color: black;
    }
    &.show {
        display: flex;
        @media (max-width: 600px) {
            display: block;
            text-align: left;
        }
    }
}

.cookie_accept {
    width: 30%;
}
